// Definitions
import type { ReactElement } from "react";
import type { ButtonsBecomeType } from "components/common/ButtonsBecome/ButtonsBecome.types";

// Components
import { LayoutCommon } from "components/common/LayoutCommon";
import { LayoutPublic } from "components/common/LayoutPublic";
import { LayoutPrivate } from "components/common/LayoutPrivate";

type LayoutWrapperProps = {
  children: ReactElement;
  navigationType?: ButtonsBecomeType;
  session?: boolean;
  hasNavigate?: boolean;
  hasPrivate?: boolean;
  hasCart?: boolean;
  hasMandate?: boolean;
  hasIntro?: boolean;
  hasAuth?: boolean;
  hasCountrySelector?: boolean;
  typeHeader?: "ghost";
};

export const LayoutWrapper = (props: LayoutWrapperProps) => {
  const {
    children,
    navigationType,
    hasPrivate,
    hasCart,
    hasMandate,
    hasIntro,
    hasNavigate,
    hasAuth,
    hasCountrySelector,
    typeHeader,
  } = props;

  if (hasAuth) {
    return (
      <LayoutCommon>
        <LayoutPrivate
          hasNavigate={hasNavigate}
          hasPrivate={hasPrivate}
          hasCart={hasCart}
          hasMandate={hasMandate}
          hasIntro={hasIntro}
        >
          {children}
        </LayoutPrivate>
      </LayoutCommon>
    );
  }

  return (
    <LayoutCommon>
      <LayoutPublic
        navigationType={navigationType}
        hasNavigate={hasNavigate}
        hasPrivate={hasPrivate}
        hasIntro={hasIntro}
        hasCountrySelector={hasCountrySelector}
        typeHeader={typeHeader}
      >
        {children}
      </LayoutPublic>
    </LayoutCommon>
  );
};
