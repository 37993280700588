// Core
import { useContext, useEffect } from "react";
import dayjs from "dayjs";

// Utils
import { cookiesService } from "utils/cookies";
import { GET_ENUMS, SURVEY_AMOUNT_SHOW_DAYS_KEY, SURVEY_LAST_SHOW_DATE_KEY } from "utils/constants";
import { isValidDate } from "utils/isValidDate";
import { PermissionContext } from "contexts/usePermissionContext";
import { PermissionAct } from "utils/permission";
import { useModalContext } from "hooks/useModalContext";

export const useProfileSurveyNPS = () => {
  const ability = useContext(PermissionContext);
  const { setOnPageModal } = useModalContext();

  useEffect(() => {
    const canShowNpsModal = ability.can(PermissionAct.READ, "nps_survey_modal");

    if (canShowNpsModal) {
      const amountOfSurveyShowDays = Number(cookiesService.get(SURVEY_AMOUNT_SHOW_DAYS_KEY));
      const lastShowDate = Number(cookiesService.get(SURVEY_LAST_SHOW_DATE_KEY));
      const isAmoutOfDaysValid = amountOfSurveyShowDays ? amountOfSurveyShowDays < 3 : true;

      if (isAmoutOfDaysValid && isValidDate(lastShowDate)) {
        setOnPageModal(GET_ENUMS.popup.static.surveyNps, true);

        const currentShowDaysAmout = amountOfSurveyShowDays
          ? Number(amountOfSurveyShowDays) + 1
          : 1;

        cookiesService.set(SURVEY_AMOUNT_SHOW_DAYS_KEY, String(currentShowDaysAmout));
        cookiesService.set(SURVEY_LAST_SHOW_DATE_KEY, String(dayjs().unix()));
      }
    }
  }, []);
};
