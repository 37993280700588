// Core
import { useTranslation } from "next-i18next";

// Definitions
import { PermissionAct } from "utils/permission";

// Components
import { Layout } from "antd";
import { Col } from "components/ui/Col";
import { Row } from "components/ui/Row";
import { Title } from "components/ui/Typography/Title";
import { Container } from "components/common/Container";
import { HeaderBanner } from "components/common/HeaderBanner";
import { Logo } from "components/common/Logo";
import { Can } from "components/common/Can";

import { useProfileContext } from "hooks/useProfileContext";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";

type LayoutPrivateHeaderProps = {
  hasMandate?: boolean;
};

export const LayoutHeaderCart = ({ hasMandate }: LayoutPrivateHeaderProps) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const titleI18nKey = hasMandate ? "cart:mandate.title" : "cart:pageTitle";

  return (
    <Layout.Header className={st.header}>
      <Can I={PermissionAct.READ} this={"banner_top"}>
        {profile && <HeaderBanner country={profile.countryCode} />}
      </Can>
      <div className={st["header-inner"]} data-test-id="cart-header">
        <Container>
          <Row gutter={rowGutters} align="middle">
            <Col span={4}>
              <Logo type="dark" alt={t("common:firstPartPageTitle")} />
            </Col>
            <Col span={16}>
              <Title tag="h1" size="24" alignmentHorizontal="center">
                {t(titleI18nKey)}
              </Title>
            </Col>
            <Col span={4} />
          </Row>
        </Container>
      </div>
    </Layout.Header>
  );
};
