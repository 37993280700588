// Components
import { Grid } from "components/ui/Grid";
import { Modal as ModalUI } from "components/ui/Modal";
import { ModalViewsSwitcher } from "components/common/ModalViewsSwitcher";

// Hooks
import { useGetPopupState } from "hooks/useGetPopupState";
import { useModalPath } from "hooks/useModalPath";

// Utils
import { getModalClosePathByName } from "utils/modals";
import { routerReplace } from "utils/router";
import { GET_ENUMS } from "utils/constants";

export const Modal = () => {
  const { md = true, lg = true } = Grid.useBreakpoint();
  const { isOpened, mountedComponent } = useGetPopupState();
  const { modalInitialPath } = useModalPath();
  const isTablet = !lg;
  const isMobile = !md;

  const onCloseModal = () => {
    void (async () => {
      const path = getModalClosePathByName(String(mountedComponent)) || modalInitialPath;
      if (
        mountedComponent === GET_ENUMS.popup.selectVehicle ||
        mountedComponent === GET_ENUMS.popup.selectorKbaKeyPreview ||
        mountedComponent === GET_ENUMS.popup.savedBasketCreate ||
        mountedComponent === GET_ENUMS.popup.cartRepairEstimateCreate ||
        mountedComponent === GET_ENUMS.popup.ordersCheckoutPaymentRejected ||
        mountedComponent === GET_ENUMS.popup.ordersCheckoutPaymentCanceled
      ) {
        await routerReplace({
          pathname: path.pathname,
        });
        return;
      }
      await routerReplace(path);
    })();
  };

  if (isMobile) {
    return (
      <ModalUI gutter="small" visible={isOpened} onClose={onCloseModal}>
        <ModalViewsSwitcher name={mountedComponent} />
      </ModalUI>
    );
  }

  if (isTablet) {
    return (
      <ModalUI gutter="middle" visible={isOpened} onClose={onCloseModal}>
        <ModalViewsSwitcher name={mountedComponent} />
      </ModalUI>
    );
  }

  return (
    <ModalUI gutter="large" visible={isOpened} onClose={onCloseModal}>
      <ModalViewsSwitcher name={mountedComponent} />
    </ModalUI>
  );
};
