// Core
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Definitions
import type { ConsentCookies } from "client/core/consent-cookies/models";

// Components
import { Form } from "antd";
import { Col } from "components/ui/Col";
import { Row } from "components/ui/Row";
import { Button } from "components/ui/Button";
import { FormBlock } from "components/ui/FormFields/FormBlock";
import { InputForm } from "components/ui/FormFields/InputForm";
import { WrappedFormCheckbox } from "components/common/WrappedFormCheckbox";
import { Text } from "components/ui/Typography/Text";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { FormConsentCookiesEnum, consentCookiesForm, inputConfig } from "./config";

import st from "./styles.module.less";

type ConsentCookiesFormProps = {
  onAllowAllCookies: () => void;
  onSubmit: (values: ConsentCookies) => void;
};

export const ConsentCookiesForm = (props: ConsentCookiesFormProps) => {
  const { onAllowAllCookies, onSubmit } = props;
  const { t } = useTranslation(["cookies"]);

  const formProps = useForm<ConsentCookies>({
    defaultValues: { ...consentCookiesForm.shape, necessary: true, statistics: true },
    resolver: zodResolver(consentCookiesForm.schema()),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit } = formProps;

  const handleSubmitForm = handleSubmit((values: ConsentCookies): void => {
    onSubmit(values);
  });

  const onSubmitForm = (event: SyntheticEvent) => {
    void (async () => {
      await handleSubmitForm(event);
    })();
  };

  return (
    <Form onFinish={onSubmitForm} className={st["consent-cookies-form"]}>
      <FormBlock>
        <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
          <Col xs={24} md={10} lg={14}>
            <div className={st.checkboxes}>
              <InputForm
                id={FormConsentCookiesEnum.necessary}
                name={FormConsentCookiesEnum.necessary}
              >
                <WrappedFormCheckbox
                  name={FormConsentCookiesEnum.necessary}
                  control={control}
                  hasDisabled
                >
                  <Text size="14">{t(inputConfig[FormConsentCookiesEnum.necessary].label)}</Text>
                </WrappedFormCheckbox>
              </InputForm>
              <InputForm
                id={FormConsentCookiesEnum.statistics}
                name={FormConsentCookiesEnum.statistics}
              >
                <WrappedFormCheckbox name={FormConsentCookiesEnum.statistics} control={control}>
                  <Text size="14">{t(inputConfig[FormConsentCookiesEnum.statistics].label)}</Text>
                </WrappedFormCheckbox>
              </InputForm>
            </div>
          </Col>
          <Col xs={24} md={7} lg={5} className={st.action}>
            <Button block type="primary" ghost htmlType="submit">
              {t("consentCookies.buttons.allowSelectionCookies")}
            </Button>
          </Col>
          <Col xs={24} md={7} lg={5} className={st.action}>
            <Button block type="primary" onClick={onAllowAllCookies}>
              {t("consentCookies.buttons.allowCookies")}
            </Button>
          </Col>
        </Row>
      </FormBlock>
    </Form>
  );
};
