// Core
import { useTranslation } from "next-i18next";

// Components
import { Grid } from "components/ui/Grid";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";
import { Link } from "components/common/Link";
import { Button } from "components/ui/Button";
import { Container } from "components/common/Container";
import { DynamicImport } from "components/common/DynamicImport/DynamicImport";
import { ConsentCookiesForm } from "client/core/consent-cookies/components/consent-cookies-form";

// Hooks
import { useConsentCookies } from "client/core/consent-cookies/hooks/use-consent-cookies";
import { useToggle } from "hooks/useToggle";

// Utils
import { book } from "init/book";
import { rowGutters } from "utils/constants";
import { getConsentCookiesItems } from "client/core/consent-cookies/utils/render-models/consent-cookies-tabs-model";
import st from "./styles.module.less";

const Tabs = DynamicImport(import("components/ui/Tabs/Tabs"), "Tabs");

export type ConsentCookiesProps = {
  testId?: string;
};

export const ConsentCookies = (props: ConsentCookiesProps) => {
  const { testId = "user-consent-cookies" } = props;

  const { t } = useTranslation(["cookies"]);
  const { md = true } = Grid.useBreakpoint();

  const { isToggle: individualSettings, onToggle: setIndividualSettings } = useToggle(false);
  const { isOpenConsentCookies, onAllowAllCookies, onRejectAllCookies, setConsentCookies } =
    useConsentCookies();

  const handleSettings = () => {
    setIndividualSettings(true);
  };

  const consentCookiesTabs = getConsentCookiesItems({ t });

  const buttonColStyle = !md && {
    xs: {
      span: 24,
    },
  };

  return isOpenConsentCookies ? (
    <div className={st["consent-cookies"]} data-test-id={testId}>
      {!individualSettings ? (
        <Container>
          <Row gutter={rowGutters} className={st["consent-cookies-content"]}>
            <Col span={24}>
              <Title tag="h4" size="20" className={st["consent-cookies-content-title"]}>
                {t("consentCookies.title")}
              </Title>
              <Text size="12" color="gray-700">
                {t("consentCookies.description")}
              </Text>
              <Link size="12" href={book.privacyPolicy}>
                {t("consentCookies.dataProtection")}
              </Link>
            </Col>
          </Row>
          <Row gutter={rowGutters} justify="end" className={st["consent-cookies-buttons"]}>
            <Col {...buttonColStyle} className={st["consent-cookies-button"]}>
              <Button
                type="primary"
                ghost
                onClick={onRejectAllCookies}
                testId={`${testId}-btn-reject`}
                block
              >
                {t("consentCookies.buttons.rejectCookies")}
              </Button>
            </Col>
            <Col {...buttonColStyle} className={st["consent-cookies-button"]}>
              <Button
                type="primary"
                ghost
                testId={`${testId}-btn-preferences`}
                onClick={handleSettings}
                block
              >
                {t("consentCookies.buttons.managePreferences")}
              </Button>
            </Col>
            <Col {...buttonColStyle} className={st["consent-cookies-button"]}>
              <Button
                type="primary"
                testId={`${testId}-btn-allow-all`}
                onClick={onAllowAllCookies}
                block
              >
                {t("consentCookies.buttons.allowCookies")}
              </Button>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row gutter={rowGutters}>
            <Col span={24}>
              <Title tag="h4" size="20">
                {t("consentCookies.individualTitle")}
              </Title>
              <Tabs defaultActiveKey="1" items={consentCookiesTabs} />
              <ConsentCookiesForm
                onSubmit={setConsentCookies}
                onAllowAllCookies={onAllowAllCookies}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  ) : null;
};
