// Core
import { useEffect } from "react";

// Definitions
import type { ReactElement } from "react";
import { ParametersEnum, GTMTypesEnum } from "client/utils/gtm/gtm.types";

// Components
import { LayoutApp } from "components/common/LayoutApp";
import { LayoutContent } from "components/common/LayoutContent";
import { LayoutFooterPrivate } from "components/common/LayoutFooterPrivate";
import { LayoutHeaderPrivate } from "components/common/LayoutHeaderPrivate";
import { LayoutHeaderCart } from "components/common/LayoutHeaderCart";
import { ConsentCookies } from "client/core/consent-cookies";
import { Modal } from "components/common/Modal";
import { SurveyNps } from "components/common/SurveyNps";

// Domains
import { useProfileContext } from "hooks/useProfileContext";

// Utils
import { gtmService } from "client/utils/gtm";

type LayoutPrivateProps = {
  children: ReactElement;
  hasNavigate?: boolean;
  hasPrivate?: boolean;
  hasCart?: boolean;
  hasMandate?: boolean;
  hasIntro?: boolean;
};

export const LayoutPrivate = (props: LayoutPrivateProps) => {
  const { children, hasPrivate, hasCart, hasMandate, hasIntro, hasNavigate } = props;
  const { profile } = useProfileContext();

  useEffect(() => {
    if (profile?.customerId) {
      gtmService.event(GTMTypesEnum.authorization, {
        [ParametersEnum.user_id]: profile?.customerId,
      });
    }
  }, []);

  return (
    <LayoutApp type="private">
      {hasCart ? <LayoutHeaderCart hasMandate={hasMandate} /> : <LayoutHeaderPrivate />}
      <LayoutContent
        hasPrivate={hasPrivate}
        hasNavigate={hasNavigate}
        noGutters={hasIntro}
        intro={hasIntro}
      >
        {children}
      </LayoutContent>
      <LayoutFooterPrivate />
      <ConsentCookies />
      <SurveyNps />
      <Modal />
    </LayoutApp>
  );
};
