// Core
import { BaseRouter } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";

type UseModalPathType = {
  modalInitialPath: Pick<BaseRouter, "query" | "pathname">;
};
export const useModalPath = (): UseModalPathType => {
  const router = useRouter();
  const rawQueryProps = router.query;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { popup, ...query } = rawQueryProps;

  const modalInitialPath = {
    pathname: router.asPath.split("?")[0],
    query,
  };

  return {
    modalInitialPath,
  };
};
