// Definitions
import { BaseRouter } from "next/dist/shared/lib/router/router";

// Utils
import { GET_ENUMS } from "utils/constants";
import { book } from "init/book";

const closeModalPaths = {
  [GET_ENUMS.popup.signupSuccess]: {
    path: book.signin,
  },
};

export const getModalClosePathByName = (modalName: string): Pick<BaseRouter, "pathname"> | void => {
  if (!modalName) return;
  const modalShape = closeModalPaths[modalName];
  return modalShape?.path ? { pathname: modalShape.path } : void 0;
};
