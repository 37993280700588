// Core
import { useTranslation, Trans } from "next-i18next";

// Components
import { Image } from "components/ui/Image";
import { Link } from "components/common/Link";
import { Text } from "components/ui/Typography/Text";
import { Title } from "components/ui/Typography/Title";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";

// Domains
import { useProfileContext } from "hooks/useProfileContext";

// Utils
import { cookiesService } from "utils/cookies";
import { SURVEY_AMOUNT_SHOW_DAYS_KEY } from "utils/constants";
import { getSurveyNPSLink } from "data/survey";
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import st from "./Styles.module.less";

export type ModalSurveyNpsProps = {
  onClose?: () => void;
};

export const ModalSurveyNps = ({ onClose }: ModalSurveyNpsProps) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const handleClickLink = () => {
    cookiesService.set(SURVEY_AMOUNT_SHOW_DAYS_KEY, "3");
    onClose?.();
  };

  return (
    <div className={st.survey}>
      <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
        <Col span={17}>
          <div className={st["survey-content"]}>
            <div className={st["survey-title"]}>
              <Title tag="h3" size="24" fontWeight="400" color="gray-400">
                {t("common:modals:survey.title")}
              </Title>
            </div>
            <div className={st["survey-text"]}>
              <Text box="full" tag="p" font="system" size="32" color="gray-200">
                <Trans
                  i18nKey={"common:modals:survey.text"}
                  components={{
                    bold: (
                      <Text
                        transform="up"
                        tag="p"
                        font="system"
                        fontWeight="900"
                        size="32"
                        color="blue-750"
                        box="inline"
                      />
                    ),
                  }}
                />
              </Text>
            </div>

            <div className={st["survey-link"]}>
              <Link
                fontWeight="600"
                size="16"
                type="banner"
                blank
                font="second"
                onClick={handleClickLink}
                href={getSurveyNPSLink(profile?.customerId, profile?.countryCode)}
              >
                <Text transform="up" color="blue-800" font="second" fontWeight="600">
                  {t("common:modals:survey.link")}
                </Text>
              </Link>
            </div>
          </div>
        </Col>
        <Col span={7}>
          <Image
            src="/assets/images/survey-pass.png"
            width={257}
            height={322}
            objectFit="contain"
            objectPosition="right center"
            alt={t("cart:empty.imageAlt")}
            className={st["survey-image"]}
          />
        </Col>
      </Row>
    </div>
  );
};
