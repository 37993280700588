// Core
import { Fragment, PropsWithChildren } from "react";

// Components
import { DynamicImport } from "components/common/DynamicImport/DynamicImport";

// Definitions
import type { ModalNoticeProps } from "components/common/ModalViews/Notice";
import type { ModalSignupProps } from "components/common/ModalViews/Signup";
import type { ModalSignupNoVatProps } from "components/common/ModalViews/SignupNoVat";
import type { ModalCallBackLimitProps } from "components/common/ModalViews/CallBackLimit";
import type { ModalCallBackPersonalLimitProps } from "components/common/ModalViews/CallBackPersonalLimit";
import type { ModalPersonalManagerCallBackProps } from "components/common/ModalViews/PersonalManagerCallBack";
import type { ModalUserCallBackProps } from "components/common/ModalViews/UserCallBack";
import type { ModalResetPasswordEmailProps } from "components/common/ModalViews/ResetPasswordEmail";
import type { ModalSignupSuccessProps } from "components/common/ModalViews/SignupSuccess";
import type { ModalSigninLeadRegisteredProps } from "components/common/ModalViews/SigninLeadRegistered";
import type { ModalSigninCustomerBlockedProps } from "components/common/ModalViews/SigninCustomerBlocked";
import type { ModalSigninErrorProps } from "components/common/ModalViews/SigninError";
import type { ModalSavedBasketCreateProps } from "components/common/ModalViews/SavedBasketCreate";
import type { ModalSavedBasketDeleteProps } from "components/common/ModalViews/SavedBasketDelete";
import type { SettingsMandateResetModalProps } from "client/core/settings-mandate";
import type { ModalSelectVehicleProps } from "components/common/ModalViews/SelectVehicle";
import type { ModalDeleteCarFromCartProps } from "components/common/ModalViews/DeleteCarFromCart";
import type { ModalCustomerDeleteConfirmProps } from "components/common/ModalViews/CustomerDeleteConfirm";
import type { ModalCustomerVehicleRemoveProps } from "components/common/ModalViews/CustomerVehicleRemove";
import type { ModalCustomerVehicleUpdateProps } from "components/common/ModalViews/CustomerVehicleUpdate";
import type { ModalRepairEstimateDeleteConfirmProps } from "components/common/ModalViews/RepairEstimateDeleteConfirm";
import type { ModalRepairEstimateCheckoutConfirmProps } from "components/common/ModalViews/RepairEstimateCheckoutConfirm";
import type { ModalCustomerCreateProps } from "components/common/ModalViews/CustomerCreate";
import type { ModalRepairEstimateStatusConfirmProps } from "components/common/ModalViews/RepairEstimateStatusConfirm";
import type { ReturnNewConfirmModalProps } from "client/core/return-new";
import type { ModalServiceTimeoutProps } from "components/common/ModalViews/ServiceTimeout";
import type { SelectorKbaKeyPreviewModalProps } from "client/core/selector-kba/components/selector-kba-key-preview-modal";
import type { ModalPasswordCreateProps } from "components/common/ModalViews/PasswordCreate";
import type { ModalChangePasswordSuccessProps } from "components/common/ModalViews/ChangePasswordSuccess";
import type { ModalCartRepairEstimateCreateProps } from "components/common/ModalViews/CartRepairEstimateCreate";
import type { ModalSavedBasketRepairEstimateCreateProps } from "components/common/ModalViews/SavedBasketRepairEstimateCreate";
import type { ModalVinIdentificationProps } from "client/core/vehicle-vin/components/vin-identification-modal/vin-identification-modal";
import type { ModalSavedBasketCheckoutConfirmProps } from "../ModalViews/SavedBasketCheckoutConfirm";
import type { ModalOrdersCheckoutServiceTimeoutProps } from "components/common/ModalViews/ModalOrdersCheckoutServiceTimeout";
import type { ModalOrdersCheckoutCompanyInvalidProps } from "components/common/ModalViews/ModalOrdersCheckoutCompanyInvalid";
import type { ModalOrdersCheckoutPaymentCanceledProps } from "client/core/orders/checkout/components/orders-checkout-payment-canceled-modal";
import type { ModalOrdersCheckoutPaymentRejectedProps } from "client/core/orders/checkout/components/orders-checkout-payment-rejected-modal";
import type { ModalCustomerVehicleCreateProps } from "client/core/customer-vehicle-preview/components/customer-vehicle-preview-modal";
import type { ModalVehicleAttrsTabsRemoveProps } from "client/core/selector-attrs/components/vehicle-attrs-tabs-remove-modal";
import type { ModalVehicleKbaTabsRemoveProps } from "client/core/selector-kba/components/vehicle-kba-tabs-remove-modal";
import type { ModalVehicleRegNumTabsRemoveProps } from "client/core/selector-reg-num/components/vehicle-reg-num-tabs-remove-modal";
import type { CatalogGraphicCategoriesLoadingFailedModalProps } from "client/core/catalog-graphic/components/catalog-graphic-categories-loading-failed-modal";
import type { ModalOrderTrackingParcelProps } from "client/core/orders/components/order-tracking-parcel";
import type { ModalVerificationSerciveTimeoutProps } from "client/core/signup-verification/components/verification-service-timeout-modal/verification-service-timeout-modal";

// Utils
import { GET_ENUMS } from "utils/constants";

type ModalViewsSwitcherProps = PropsWithChildren<{
  onClose?: () => void;
  name?: string;
  params?: {
    id?: number;
  };
}>;

export const ModalViewsSwitcher = (props: ModalViewsSwitcherProps) => {
  const { name, params, onClose } = props;

  switch (name) {
    case GET_ENUMS.popup.catalogGraphicCategoriesLoadingFailed: {
      const ModalCatalogGraphicLoadFailedDynamic =
        DynamicImport<CatalogGraphicCategoriesLoadingFailedModalProps>(
          import(
            "../../../client/core/catalog-graphic/components/catalog-graphic-categories-loading-failed-modal"
          ),
          "CatalogGraphicCategoriesLoadingFailedModal",
        );
      return <ModalCatalogGraphicLoadFailedDynamic />;
    }
    case GET_ENUMS.popup.vehicleAttrsTabsClose: {
      const ModalVehicleAttrsTabsRemoveDynamic = DynamicImport<ModalVehicleAttrsTabsRemoveProps>(
        import("../../../client/core/selector-attrs/components/vehicle-attrs-tabs-remove-modal"),
        "ModalVehicleAttrsTabsRemove",
      );
      return <ModalVehicleAttrsTabsRemoveDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.vehicleKbaTabsClose: {
      const ModalVehicleKbaTabsRemoveDynamic = DynamicImport<ModalVehicleKbaTabsRemoveProps>(
        import("../../../client/core/selector-kba/components/vehicle-kba-tabs-remove-modal"),
        "ModalVehicleKbaTabsRemove",
      );
      return <ModalVehicleKbaTabsRemoveDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.vehicleRegNumTabsClose: {
      const ModalVehicleRegNumTabsRemoveDynamic = DynamicImport<ModalVehicleRegNumTabsRemoveProps>(
        import(
          "../../../client/core/selector-reg-num/components/vehicle-reg-num-tabs-remove-modal"
        ),
        "ModalVehicleRegNumTabsRemove",
      );
      return <ModalVehicleRegNumTabsRemoveDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.notice: {
      const ModalNoticeDynamic = DynamicImport<ModalNoticeProps>(
        import("../ModalViews/Notice"),
        "ModalNotice",
      );
      return <ModalNoticeDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.signup: {
      const ModalSignupDynamic = DynamicImport<ModalSignupProps>(
        import("../ModalViews/Signup"),
        "ModalSignup",
      );
      return <ModalSignupDynamic />;
    }
    case GET_ENUMS.popup.signupNoVat: {
      const ModalSignupNoVatDynamic = DynamicImport<ModalSignupNoVatProps>(
        import("../ModalViews/SignupNoVat"),
        "ModalSignupNoVat",
      );
      return <ModalSignupNoVatDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.callBack: {
      const ModalUserCallBackDynamic = DynamicImport<ModalUserCallBackProps>(
        import("../ModalViews/UserCallBack"),
        "ModalUserCallBack",
      );
      return <ModalUserCallBackDynamic />;
    }
    case GET_ENUMS.popup.callBackLimit: {
      const ModalCallBackLimitDynamic = DynamicImport<ModalCallBackLimitProps>(
        import("../ModalViews/CallBackLimit"),
        "ModalCallBackLimit",
      );
      return <ModalCallBackLimitDynamic />;
    }
    case GET_ENUMS.popup.personalCallBackLimit: {
      const ModalCallBackPersonalLimitDynamic = DynamicImport<ModalCallBackPersonalLimitProps>(
        import("../ModalViews/CallBackPersonalLimit"),
        "ModalCallBackPersonalLimit",
      );
      return <ModalCallBackPersonalLimitDynamic />;
    }
    case GET_ENUMS.popup.callBackPersonalManager: {
      const ModalPersonalManagerCallBackDynamic = DynamicImport<ModalPersonalManagerCallBackProps>(
        import("../ModalViews/PersonalManagerCallBack"),
        "ModalPersonalManagerCallBack",
      );
      return <ModalPersonalManagerCallBackDynamic />;
    }
    case GET_ENUMS.popup.resetPasswordEmail: {
      const ModalResetPasswordEmailDynamic = DynamicImport<ModalResetPasswordEmailProps>(
        import("../ModalViews/ResetPasswordEmail"),
        "ModalResetPasswordEmail",
      );
      return <ModalResetPasswordEmailDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.signupSuccess: {
      const ModalSignupSuccessDynamic = DynamicImport<ModalSignupSuccessProps>(
        import("../ModalViews/SignupSuccess"),
        "ModalSignupSuccess",
      );
      return <ModalSignupSuccessDynamic />;
    }
    case GET_ENUMS.popup.leadRegistered: {
      const ModalSigninLeadRegisteredDynamic = DynamicImport<ModalSigninLeadRegisteredProps>(
        import("../ModalViews/SigninLeadRegistered"),
        "ModalSigninLeadRegistered",
      );
      return <ModalSigninLeadRegisteredDynamic />;
    }
    case GET_ENUMS.popup.customerBlocked: {
      const ModalSigninCustomerBlockedDynamic = DynamicImport<ModalSigninCustomerBlockedProps>(
        import("../ModalViews/SigninCustomerBlocked"),
        "ModalSigninCustomerBlocked",
      );
      return <ModalSigninCustomerBlockedDynamic />;
    }
    case GET_ENUMS.popup.signinError: {
      const ModalSigninErrorDynamic = DynamicImport<ModalSigninErrorProps>(
        import("../ModalViews/SigninError"),
        "ModalSigninError",
      );
      return <ModalSigninErrorDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.savedBasketCreate: {
      const ModalSavedBasketCreateDynamic = DynamicImport<ModalSavedBasketCreateProps>(
        import("../ModalViews/SavedBasketCreate"),
        "ModalSavedBasketCreate",
      );
      return <ModalSavedBasketCreateDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.savedBasketDelete: {
      const ModalSavedBasketDeleteDynamic = DynamicImport<ModalSavedBasketDeleteProps>(
        import("../ModalViews/SavedBasketDelete"),
        "ModalSavedBasketDelete",
      );
      return <ModalSavedBasketDeleteDynamic onClose={onClose} />;
    }

    case GET_ENUMS.popup.savedBasketCheckoutConfirm: {
      const ModalSavedBasketCheckoutConfirmDynamic =
        DynamicImport<ModalSavedBasketCheckoutConfirmProps>(
          import("../ModalViews/SavedBasketCheckoutConfirm"),
          "ModalSavedBasketCheckoutConfirm",
        );
      return <ModalSavedBasketCheckoutConfirmDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.mandateReset: {
      const ModalMandateResetDynamic = DynamicImport<SettingsMandateResetModalProps>(
        import("client/core/settings-mandate/components/settings-mandate-reset-modal"),
        "SettingsMandateResetModal",
      );
      return <ModalMandateResetDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.selectVehicle: {
      const ModalSelectVehicleDynamic = DynamicImport<ModalSelectVehicleProps>(
        import("../ModalViews/SelectVehicle"),
        "ModalSelectVehicle",
      );
      return <ModalSelectVehicleDynamic />;
    }
    case GET_ENUMS.popup.deleteCarFromCart: {
      const ModalDeleteCarFromCartDynamic = DynamicImport<ModalDeleteCarFromCartProps>(
        import("../ModalViews/DeleteCarFromCart"),
        "ModalDeleteCarFromCart",
      );
      return <ModalDeleteCarFromCartDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.static.customerVehicleCreate: {
      const ModaleVehiclePreviewDynamic = DynamicImport<ModalCustomerVehicleCreateProps>(
        import(
          "../../../client/core/customer-vehicle-preview/components/customer-vehicle-preview-modal"
        ),
        "ModalVehiclePreview",
      );
      return <ModaleVehiclePreviewDynamic params={params} onClose={onClose} />;
    }
    case GET_ENUMS.popup.customerCreate: {
      const ModalCustomerCreateDynamic = DynamicImport<ModalCustomerCreateProps>(
        import("../ModalViews/CustomerCreate"),
        "ModalCustomerCreate",
      );
      return <ModalCustomerCreateDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.customerDeleteConfirm: {
      const ModalCustomerDeleteConfirmDynamic = DynamicImport<ModalCustomerDeleteConfirmProps>(
        import("../ModalViews/CustomerDeleteConfirm"),
        "ModalCustomerDeleteConfirm",
      );
      return <ModalCustomerDeleteConfirmDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.static.customerVehicleRemove: {
      const ModalCustomerVehicleRemoveDynamic = DynamicImport<ModalCustomerVehicleRemoveProps>(
        import("../ModalViews/CustomerVehicleRemove"),
        "ModalCustomerVehicleRemove",
      );
      return <ModalCustomerVehicleRemoveDynamic onClose={onClose} id={Number(params?.id)} />;
    }
    case GET_ENUMS.popup.static.customerVehicleUpdate: {
      const ModalCustomerVehicleUpdateDynamic = DynamicImport<ModalCustomerVehicleUpdateProps>(
        import("../ModalViews/CustomerVehicleUpdate"),
        "ModalCustomerVehicleUpdate",
      );
      return <ModalCustomerVehicleUpdateDynamic onClose={onClose} id={Number(params?.id)} />;
    }
    case GET_ENUMS.popup.repairEstimateDeleteConfirm: {
      const ModalRepairEstimateDeleteConfirmDynamic =
        DynamicImport<ModalRepairEstimateDeleteConfirmProps>(
          import("../ModalViews/RepairEstimateDeleteConfirm"),
          "ModalRepairEstimateDeleteConfirm",
        );
      return <ModalRepairEstimateDeleteConfirmDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.repairEstimateCheckoutConfirm: {
      const ModalRepairEstimateCheckoutConfirmDynamic =
        DynamicImport<ModalRepairEstimateCheckoutConfirmProps>(
          import("../ModalViews/RepairEstimateCheckoutConfirm"),
          "ModalRepairEstimateCheckoutConfirm",
        );
      return <ModalRepairEstimateCheckoutConfirmDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.cartRepairEstimateCreate: {
      const ModalCartRepairEstimateCreateDynamic =
        DynamicImport<ModalCartRepairEstimateCreateProps>(
          import("../ModalViews/CartRepairEstimateCreate"),
          "ModalCartRepairEstimateCreate",
        );
      return <ModalCartRepairEstimateCreateDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.savedBasketRepairEstimateCreate: {
      const ModalSavedBasketRepairEstimateCreateDynamic =
        DynamicImport<ModalSavedBasketRepairEstimateCreateProps>(
          import("../ModalViews/SavedBasketRepairEstimateCreate"),
          "ModalSavedBasketRepairEstimateCreate",
        );
      return <ModalSavedBasketRepairEstimateCreateDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.repairEstimateClientCreateVehicle: {
      const ModalRepairEstimateClientCreateVehicleDynamic = DynamicImport(
        import("../ModalViews/RepairEstimateClientCreateVehicle"),
        "ModalRepairEstimateClientCreateVehicle",
      );
      return <ModalRepairEstimateClientCreateVehicleDynamic />;
    }
    case GET_ENUMS.popup.repairEstimateClientChangeVehicle: {
      const ModalRepairEstimateClienChangeVehicleDynamic = DynamicImport(
        import("../ModalViews/RepairEstimateClientChangeVehicle"),
        "ModalRepairEstimateClientChangeVehicle",
      );
      return <ModalRepairEstimateClienChangeVehicleDynamic />;
    }
    case GET_ENUMS.popup.static.repairEstimateStatusChangeConfirm: {
      const ModalRepairEstimateStatusConfirmDynamic =
        DynamicImport<ModalRepairEstimateStatusConfirmProps>(
          import("../ModalViews/RepairEstimateStatusConfirm"),
          "ModalRepairEstimateStatusConfirm",
        );
      return <ModalRepairEstimateStatusConfirmDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.returnConfirmCreate: {
      const ModalReturnConfirmCreateDynamic = DynamicImport<ReturnNewConfirmModalProps>(
        import("client/core/return-new/components/return-new-confirm-modal"),
        "ReturnNewConfirmModal",
      );
      return <ModalReturnConfirmCreateDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.trackParcel: {
      const ModalOrderTrackParcelDynamic = DynamicImport<ModalOrderTrackingParcelProps>(
        import("client/core/orders/components/order-tracking-parcel"),
        "ModalOrderTrackingParcel",
      );
      return <ModalOrderTrackParcelDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.selectorKbaKeyPreview: {
      const SelectorKbaKeyPreviewModalDynamic = DynamicImport<SelectorKbaKeyPreviewModalProps>(
        import("../../../client/core/selector-kba/components/selector-kba-key-preview-modal"),
        "SelectorKbaKeyPreviewModal",
      );
      return <SelectorKbaKeyPreviewModalDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.passwordCreate: {
      const PasswordCreateDynamic = DynamicImport<ModalPasswordCreateProps>(
        import("../ModalViews/PasswordCreate"),
        "ModalPasswordCreate",
      );
      return <PasswordCreateDynamic />;
    }
    case GET_ENUMS.popup.changePasswordSuccess: {
      const ChangePasswordSuccessDynamic = DynamicImport<ModalChangePasswordSuccessProps>(
        import("../ModalViews/ChangePasswordSuccess"),
        "ModalChangePasswordSuccess",
      );
      return <ChangePasswordSuccessDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.vinIdentification: {
      const VinIdentificationDynamic = DynamicImport<ModalVinIdentificationProps>(
        import("../../../client/core/vehicle-vin/components/vin-identification-modal"),
        "ModalVinIdentification",
      );
      return <VinIdentificationDynamic />;
    }
    case GET_ENUMS.popup.static.serviceTimeout: {
      const ModalServiceTimeoutDynamic = DynamicImport<ModalServiceTimeoutProps>(
        import("../ModalViews/ServiceTimeout"),
        "ModalServiceTimeout",
      );
      return <ModalServiceTimeoutDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.static.ordersCheckoutServiceTimeout: {
      const ModalOrdersCheckoutServiceTimeoutDynamic =
        DynamicImport<ModalOrdersCheckoutServiceTimeoutProps>(
          import("../ModalViews/ModalOrdersCheckoutServiceTimeout"),
          "ModalOrdersCheckoutServiceTimeout",
        );
      return <ModalOrdersCheckoutServiceTimeoutDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.static.ordersCheckoutCompanyInvalid: {
      const ModalOrdersCheckoutCompanyInvalidDynamic =
        DynamicImport<ModalOrdersCheckoutCompanyInvalidProps>(
          import("../ModalViews/ModalOrdersCheckoutCompanyInvalid"),
          "ModalOrdersCheckoutCompanyInvalid",
        );
      return <ModalOrdersCheckoutCompanyInvalidDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.ordersCheckoutPaymentCanceled: {
      const ModalOrdersCheckoutCompanyInvalidDynamic =
        DynamicImport<ModalOrdersCheckoutPaymentCanceledProps>(
          import(
            "../../../client/core/orders/checkout/components/orders-checkout-payment-canceled-modal"
          ),
          "ModalOrdersCheckoutPaymentCanceled",
        );
      return <ModalOrdersCheckoutCompanyInvalidDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.ordersCheckoutPaymentRejected: {
      const ModalOrdersCheckoutCompanyInvalidDynamic =
        DynamicImport<ModalOrdersCheckoutPaymentRejectedProps>(
          import(
            "../../../client/core/orders/checkout/components/orders-checkout-payment-rejected-modal"
          ),
          "ModalOrdersCheckoutPaymentRejected",
        );
      return <ModalOrdersCheckoutCompanyInvalidDynamic onClose={onClose} />;
    }
    case GET_ENUMS.popup.static.verificationServiceTimeout: {
      const ModalVerificationSerciveTimeoutDynamic =
        DynamicImport<ModalVerificationSerciveTimeoutProps>(
          import(
            // eslint-disable-next-line max-len
            "../../../client/core/signup-verification/components/verification-service-timeout-modal/verification-service-timeout-modal"
          ),
          "ModalVerificationSerciveTimeout",
        );
      return <ModalVerificationSerciveTimeoutDynamic onClose={onClose} />;
    }
    default:
      return <Fragment />;
  }
};
